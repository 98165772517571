.newButton {
    margin: 3rem 0 3rem 0;
    background-color: rgb(26, 179, 148);
    color: rgb(255, 255, 255);
    appearance: none;
    border: none;
    border-radius: 1.214em;
    cursor: pointer;
    font-family: inherit;
    font-size: .875em;
    font-weight: bold;
    outline: none;
    padding: 0.5em 2.786em 0.6em;
    text-align: left;
}
.listContainerMobile {
    display: flex;
    width: 100%;
    padding-top: 1rem;
}

.listItemHeaderMobile {
    flex-direction: column;
    display: flex;
    width: 100%;
    
    border-bottom: 1px;
    border-bottom-style: solid;
    padding: 2px 0 2px 0;
    z-index: 1;
    position: relative;
}

.listItemHeaderMobile:hover {
    background-color: var(--blue4);
    cursor: pointer;
}

.listItemHeaderMobile:last-child {
    border: none;
}

.cellHeaderMobile {
    padding: 4px;
    margin: 0;
}
.gapList {
    display: none;
    flex-direction: column;
    margin-right: 8px;
    gap: 4px;
    padding-right: 8px;
}

.gap {
    margin: 0;
}

.legend {
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 6px;
    width: 98%;
}

.legend>p {
    margin: 4px;
}

.gapHeader {
    margin-top: 32px;
    width: 98%;
}
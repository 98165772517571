
.pdfStyles {
    display: flex;
    margin: 16px;
}

.pdfStyles * {
    box-shadow: none;
    width: 100%;
    font-family: "Campton";
}

.marker {
    list-style-type: none;
}

.marker li::before {
    content: "- ";
    color: black;
}

.visible {
    display: flex;
}

.initialPageBody {
    padding: 8px 64px 0 8px;
}

.pageBody {
    padding: 0 64px 0 0;
}

.legendsPdf {
    flex-direction: row;
    flex-wrap: nowrap !important;
}
.gapList {
    display: none;
    flex-direction: column;
    margin-right: 8px;
    gap: 4px;
    padding: 0 8px 0 8px;
}

.gap {
    margin: 0;
}

.legends {
    display: flex;
    gap: 8px;
    flex-wrap: wrap;
    margin-bottom: 2rem;
}

.legend {
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 6px;
    width: 98%;
}

.legend>p {
    margin: 4px;
}

.gapHeader {
    margin-top: 32px;
    width: 98%;
}

.noContent {
    margin-left: 2rem;
}

.noContent p {
    font-size: 20px;
    font-weight: 500;
}
.completedScreen {
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    justify-content: center;
    min-height: 100vh;
    width: 100%;
    background-color: var(--white);
}

.spinnerWrapper {
    display: flex;
    justify-content: center;
    align-items: center;
    min-height: 90vh;
}

.mobileCompletedScreen {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-bottom: 1rem;
}
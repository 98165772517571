.result {
    width: fit-content;
    max-width: 800px;
    border-radius: 16px;
    background-color: var(--white);
    padding: 2rem;
    box-shadow: 0 4px 4px hsl(0deg 0% 0% / 0.3);

    margin: 1rem;
}

.result h3 {
    font-size: 28px;
    font-weight: 600;
    font-family: "Campton-Light";
}

.result h4 {
    font-size: 20px;
    font-weight: 500;
}

.marker::marker {
    color: var(--black);
    content: "- ";
}

.infoContainer {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: fit-content;
    max-width: 700px;
    background-color: var(--white);
    margin-inline: 1rem;
}

.contact {
    box-shadow: 0 4px 4px hsl(0deg 0% 0% / 0.3);
    border-radius: 16px;
    padding: 2rem;
    margin-block: 1rem;

    display: flex;
    flex-direction: column;
}

.contact h3 {
    font-size: 28px;
    font-weight: 600;
    font-family: "Campton-Light";
}

.contact a {
    color: var(--black);
}

.subHeader {
    margin: 16px 0 4px 0
}

.image {
    width: 100px;
    height: 100px;
    padding: 10px 10px 0 0 !important;
    align-self: center;
}

.buttonsContainer {
    display: flex;
    justify-content: space-evenly;
    width: 100%;
    flex-wrap: wrap;
}

.newButton {
    text-decoration: none;
    background-color: var(--black);
    color: var(--white);
    padding: 8px 16px 8px 16px;
    border-radius: 10px;
    font-family: "Campton-Book", "Verdana";
    font-weight: 400;
    box-shadow: 0 4px 4px hsl(0deg 0% 0% / 0.3);
    font-size: 20px;

    display: flex;
    align-items: center;
}

.pdfButton {
    text-decoration: none;
    background-color: var(--yellow);
    color: var(--black);
    padding: 8px 16px 8px 16px;
    border-radius: 10px;
    font-family: "Campton-Book", "Verdana";
    font-weight: 400;
    box-shadow: 0 4px 4px hsl(0deg 0% 0% / 0.3);
    border: none;
    font-size: 20px;
    cursor: pointer;
}

.buttonsContainer>button {
    margin: 8px;
}

.link>a {
    color: var(--black);
}

.link * a {
    color: var(--black);
}

.link::marker {
    color: var(--black);
    content: "- ";
}

.projectInfo {
    display: flex;
    flex-direction: column;
    width: 100%;
    max-width: 900px;
    justify-content: space-between;
}

.infoRow {
    font-size: 20px;
    font-weight: 500;
    display: flex;
    margin-top: 5px;
}

.infoRow>p {
    margin: 0;
    padding-left: 5px;
}

.pdfImageContainer {
    width: 100%;
    min-width: 600px;
    display: none;
}

.innerContainer {
    display: flex;
    justify-content: space-between;
    flex-direction: row;
}

.pdfImage {
    object-fit: contain;
    width: 200px;
}

.pageNumberInitial {
    min-width: 30px;
}

.pageContainer {
    display: none;
}

.pageNumber {
    display: flex;
    width: 100%;
    justify-content: flex-end;
}

.noContent {
    margin-left: 2rem;
}

.noContent p {
    font-size: 20px;
    font-weight: 500;
}


.legends {
    display: flex;
    gap: 8px;
    flex-wrap: wrap;
    margin-bottom: 2rem;
}

.legend {
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 6px;
    width: 98%;
}

.legend>p {
    margin: 4px;
}

.pdfContents {
    padding-top: 10;
    display: none
}
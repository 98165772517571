.listContainer {
    width: 85vw;
    padding: 2rem;
    background-color: var(--white);
    border-radius: 8px;
    text-align: center;
    margin-bottom: 3rem;
    margin-top: 2rem;
}

.listItem {
    flex-direction: row;
    display: flex;
    justify-content: space-between;
    border-bottom: 1px;
    border-bottom-style: solid;
    text-decoration: none;
}

.innerContainer {
    display: flex;
    width: 100%;
}

.listItem:last-child {
    border-bottom: none;
}

.listItem:hover {
    background-color: var(--blue4);
    cursor: pointer;
}

.listItemHeader {
    flex-direction: row;
    display: flex;
    justify-content: space-between;
    border-bottom: 2px;
    border-bottom-style: solid;
}

.cell {
    width: 25%;
    min-width: 150px;
    color: var(--grey1);
    margin: 8px;
    font-size: 16px;
}

.cellHeader {
    width: 25%;
    min-width: 150px;
    color: var(--grey1);
    margin: 8px;
    font-size: 20px;
}

.spinnerWrapper {
    display: flex;
    align-items: center;
    justify-content: center;
}

.noContent {
    font-size: 20px;
    margin-top: 2rem;
}


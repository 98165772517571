@tailwind base;
@tailwind components;
@tailwind utilities;

@layer base {
  a {
    text-decoration: underline;
  }
  br {
    line-height: 2px;
  }
}

@layer utilities {
  .noScrollbar::-webkit-scrollbar {
    width: 12px;
    height: 12px;
    opacity: 0%;
  }

  .scrollbar::-webkit-scrollbar {
    width: 12px;
    height: 12px;
    opacity: 100%;
  }

  .scrollbar::-webkit-scrollbar-track {
    border-radius: 100vh;
    background: hsla(0, 0%, 94%, 1);
  }

  .scrollbar::-webkit-scrollbar-thumb {
    background: hsl(0, 0%, 90%);
    border-radius: 100vh;
    border: 3px solid hsla(0, 0%, 94%, 1);
  }

  .scrollbar::-webkit-scrollbar-thumb:hover {
    background: hsl(0, 0%, 75%);
  }
}

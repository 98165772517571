.completedHtml {
    height: 100%;
    text-align: left;
    padding: 1rem 3rem 1rem 3rem;
    border-radius: 8px;
}

.completedHtml::before {
    display: none;
}

.completedHtml>p {
    font-size: 18px;
    font-weight: normal;
}

.checkboxQuestions {
    display: flex;
    flex-direction: row-reverse;
    justify-content: left;
}

.checkboxContentOne {
    min-width: 150px;
}

.checkboxContentTwo {
    min-width: 300px;
}

.checkboxColumn {
    display: contents !important;
}

.questionTestWidth {
    max-width: 100%;
}

.noTextWrap {
    white-space: nowrap;
}

.finals {
    display: flex;
    justify-content: space-between;
    flex-direction: row;
}

.estBudget {
    display: flex;
    justify-content: space-between;
    flex-direction: column;
}

.finalsMobile {
    display: flex;
    justify-content: space-between;
    flex-direction: column;
}

.titleFinals {
    text-align: left;
    max-width: 100%;
}

.fontWeight>span {
    font-weight: 400 !important;
}

.options {
    padding-right: 0px !important;
}

.optionsContainer {
    width: 100%;
    min-width: 420px;
}

.optionsContainerMobile {
    width: 100%;
}

.influencePanelTitle * {
    font-family: "Campton-Light" !important;
    font-weight: bold !important;
    font-size: 20px !important;
}

.titlePanel {
    color: var(--black);
}

.reflectPanelTitle * {
    font-family: "Campton-Light";
    font-size: 20px !important;
}

.commentField {
    min-width: 100%;
}

.surveyContent {
    width: 70%;
    max-width: 1500px;
    align-self: center;
    margin: 2rem 0 2rem 0;
}

.mobileContent {
    width: 100%;
    align-self: center;
    margin: 4vh 0 4vh 0;
}

.defaultRootStyles {
    width: 100%;
}

.surveyGapContainer {
    width: 100%;
    display: flex;
    flex-direction: row;
}

.gapList {
    width: 50%;
    border-left-width: 2px;
    border-left-style: solid;
}

.gapListMobile {
    width: 100%;
}

.surveyBody {
    box-shadow: 0 4px 4px hsl(0deg 0% 0% / 0.3);
    padding: 1rem 2rem 1rem 2rem;
    border-radius: 10px;
}

.surveyBodyMobile *,
.surveyBody * {
    font-family: "Campton-Book";
    color: var(--black);
}

.surveyBodyMobile span,
.surveyBody span {
    font-weight: 400;
    font-size: 16px;
}

.questionReflectId h4 {
    font-weight: 500 !important;
}

.surveyBodyMobile h1,
.surveyBody h1 {
    font-family: "Campton-Light";
    font-size: 20px;
    font-weight: 500;
}

.surveyBodyMobile h3,
.surveyBody h3 {
    font-family: "Campton-Light";
    font-size: 28px;
    font-weight: bold;
}

.surveyBodyMobile h4,
.surveyBody h4 {
    font-family: "Campton-Light";
    font-size: 20px;
    font-weight: bold;
}

.surveyBodyMobile h5,
.surveyBody h5 {
    font-size: 16px;
    font-weight: 400;
}

.surveyBodyMobile h4>span,
.surveyBody h4>span {
    font-weight: 600;
    font-size: 16px;
}

.surveyBodyMobile h5>span,
.surveyBody h5>span {
    font-weight: 600;
    font-size: 16px;
}

.surveyBodyMobile a,
.surveyBody a {
    color: var(--black);
}

.surveyBodyMobile ul li::marker,
.surveyBody ul li::marker {
    color: var(--black);
    content: "- ";
}

.questionTestWidth>span {
    font-weight: 400 !important;
}

.imagePickerList {
    margin-right: 0px !important;
}

.imagePickerListMobile {
    width: 90px;
}

.imagePickerContainer {
    max-width: 800px;
}

.imagePickerContainerMobile {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
}

.exampleQuestion>label>span>svg {
    background-color: var(--blue4) !important;
}

.fontWeight>div>div>h5 {
    font-weight: 600;
}

.booleanOutline {
    outline-color: var(--blue4) !important;
}

.boxSize {
    height: 31px;
    min-height: 31px;
    min-width: 20%;
    margin-bottom: 2em;
}

.reflectPanel {
    padding-bottom: 0px !important;
}

.navButton {
    background-color: var(--yellow);
    color: var(--black);
    padding: 0.5em 2.786em 0.6em;
    border-radius: 1.214em;
    font-family: "Campton-Book", "Verdana";
    font-weight: 400;
    border: none;
    cursor: pointer;
    font-size: 16px;
    outline: none;
}

.inVisNavButton {
    background-color: var(--yellow);
    color: var(--black);
    padding: 0.5em 2.786em 0.6em;
    border-radius: 1.214em;
    font-family: "Campton-Book", "Verdana";
    font-weight: 400;
    border: none;
    cursor: pointer;
    font-size: 16px;
    outline: none;
    display: none;
}

.navButtonsContainer {
    display: flex;
    justify-content: space-between;
    padding: 0 4px 0 4px;
    flex-wrap: wrap;
    margin: 3rem 0 1rem 0;
}

.imageContainer {
    justify-content: center;
    position: relative;
    display: none;
    text-align: center;
    max-width: 2000px;
}

.imageContainerMobile {
    justify-content: center;
    display: none;
    text-align: center;
    flex-direction: column;
    align-items: center;
    padding: 1vmin;
}

.imageText {
    position: absolute;
    bottom: 2rem;
    text-align: center;
    left: 20%;
    right: 20%;
    color: var(--white);
    font-size: 28px;
    font-weight: 600;
    font-family: "Campton-Light";
}

.mediumImageText {
    position: absolute;
    bottom: 1rem;
    text-align: center;
    left: 20%;
    right: 20%;
    color: var(--white);
    font-size: 20px;
    font-weight: 600;
    font-family: "Campton-Light";
}

.smallImageText {
    position: absolute;
    bottom: 1rem;
    text-align: center;
    left: 20%;
    right: 20%;
    color: var(--white);
    font-size: 16px;
    font-weight: 600;
    font-family: "Campton-Light";
}

.tinyImageText {
    text-align: center;
    color: var(--black);
    font-size: 16px;
    font-weight: 600;
    font-family: "Campton-Light";
    margin: 0;
    width: 70%;
}

.aboutHeaderElement h3 {
    margin: 0;
}

.aboutHeaderPadding {
    padding-bottom: 1vmin !important;
}

.infoBar {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-wrap: wrap;
}

.emptyDiv {
    width: 15%;
}

.bodyPage {
    display: flex;
    width: 100%;
    justify-content: center;
}


.welcomeText * h3 {
    text-align: center;
    padding-top: 0;
    margin-top: 0;
}

.orientationImage {
    margin-block: 1rem;
}

.dialogBox {
    border: none;
    border-radius: 8px;
    background-color: var(--greige);
    box-shadow: 6px 8px var(--grey3);
}

.dialogBoxContent {
    display: flex;
    flex-direction: column;
}

.dialogBoxHeader {
    align-items: center;
    justify-content: space-between;
}

.dialogBoxHeader>h3 {
    margin: 0 24px 0 24px;
}

.dialogBoxBody {
    margin: 24px 24px 12px 24px;
    max-width: 500px;
}

.dialogBoxClose {
    border: none;
    padding: 12px 12px;
    font-size: 16px;
    margin: 4px 2px;
    cursor: pointer;
}

.dialogBoxFooter {
    margin: 4px 2px;
    cursor: pointer;
}

.dialogBoxBtn {
    margin-left: 10px;
    background-color: black;
    color: white;
    border-radius: 4px;
    padding: 0px 8px 0px 8px;
    font-family: "Campton-Book";
    cursor: pointer;
}

.dialogBoxBtn:hover {
    background-color: rgba(0, 0, 0, 0.8);
}

.popupdescription {
    display: flex;
    flex-direction: row-reverse;
    align-items: center;
}

.retryButtonStyles {
    margin: 0;

    text-decoration: none;
    background-color: var(--black);
    color: var(--white);
    padding: 8px 16px 8px 16px;
    border-radius: 10px;
    font-family: "Campton-Book", "Verdana";
    font-weight: 400;
    border: none;
    font-size: 20px;
    cursor: pointer;
}
:root {
  --black: #000000;
  --grey1: #323232;
  --grey2: #505050;
  --grey3: #7E7E7E;
  --grey4: #B2B2B2;
  --grey5: #D0D0D0;
  --grey6: #F8F8F8;
  --white: #ffffff;
  --greige: #E9E6E0;
  --yellow: #F3FFAF;

  --green1: #405040;
  --green2: #707C70;
  --green3: #A0A8A0;
  --green4: #CFD3CF;

  --blue1: #405070;
  --blue2: #707C94;
  --blue3: #A0A8B8;
  --blue4: #CFD3DB;

  --brown1: #504030;
  --brown2: #7C7064;
  --brown3: #A8A098;
  --brown4: #D3CFCB;

  /* portal */
  --danger: #ce5757;
  --danger-hover: #b14a4a;
  --success: #67a799;
  --success-hover: #5c9286;
  --warning: #f6ce8e;
  --warning-hover: #efbf72;
}

body {
  margin: 0;
  font-family: 'Campton-Book';
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

/* body::-webkit-scrollbar {
  width: 16px;
  height: 16px;
}

body:-webkit-scrollbar-track {
  border-radius: 100vh;
  background: hsla(0, 0%, 94%, 1);
}

body::-webkit-scrollbar-thumb {
  background: hsl(0, 0%, 90%);
  border-radius: 100vh;
  border: 3px solid hsla(0, 0%, 94%, 1);
}

body::-webkit-scrollbar-thumb:hover {
  background: hsl(0, 0%, 75%);
} */

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

/* other formats include: 'woff2', 'truetype, 'opentype',
                          'embedded-opentype', and 'svg' */
@font-face {
  font-family: 'Campton-Book';
  src: local('Campton-Book'), url(../assets/fonts/campton-book.otf) format('opentype');
}

@font-face {
  font-family: 'Campton-Light';
  src: local('Campton-Light'), url(../assets/fonts/campton-light.otf) format('opentype');
}
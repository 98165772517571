.containerStyle {
    position: relative;
    width: 3rem;
    height: 3rem;
    box-sizing: border-box;
    margin-top: 1rem;
}

.circleStyle {
    display: block;
    width: 3rem;
    height: 3rem;
    border: 0.5rem solid var(--grey5);
    border-top: 0.5rem solid var(--blue1);
    border-radius: 50%;
    position: absolute;
    box-sizing: border-box;
    top: 0;
    left: 0;
}
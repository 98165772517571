.header {
    border-bottom-width: 0.5px;
    border-bottom-style: solid;
    border-bottom-color: black;
    width: 100%;
    height: calc(16px + 7vmin);
    flex-direction: row;
}

.burgerHeader {
    border-bottom-width: 0.5px;
    border-bottom-style: solid;
    border-bottom-color: black;
    width: 100%;
    height: 5rem;
    align-items: center;
    justify-content: center;
    display: flex;
}

.headerContainer {
    display: flex;
    align-items: center;
    height: calc(10px + 8vmin);
    padding-left: 2rem;
    padding-right: 2rem;
}

.brand {
    display: flex;
    min-width: 100px;
    align-items: center;
    text-decoration: none;

    width: 30%;
}

.BurgerBrand {
    display: flex;
    width: 100%;
    align-items: center;
}

.HeaderTitle {
    font-size: 28px;
    font-weight: 400;
    color: var(--grey1);
    margin-block-start: 0px;
    margin-block-end: 0px;
    text-decoration: none;
    font-family: "Campton-Light";
    text-align: center;
    
    width: 40%;
}

.HeaderTitle:hover {
    color: var(--grey3);
}

.rightCorner {
    display: flex;
    flex-direction: row;
    justify-content: right;
    align-items: center;
    min-width: 350px;
    width: 30%;

}

.HeaderLink {
    font-size: 16px;
    color: var(--black);
    margin: 0px;
    margin-right: 1.5rem;
    text-decoration: none;
}

.HeaderLink:hover {
    color: var(--grey3);
}

.logout {
    background-color: var(--yellow);
    color: var(--black);
    font-family: "Campton-Book", "Verdana";
    font-weight: 400;
    position: absolute;
    right: 2rem;
    margin-top: 1rem;

    text-decoration: none;
    padding: 8px 16px 8px 16px;
    border-radius: 10px;
    box-shadow: 0 4px 4px hsl(0deg 0% 0% / 0.3);
    border: none;
    font-size: 20px;
    cursor: pointer;
}


/* not working for nextjs image */
.image {
    border-color: black;
    border-style: solid;
    border-width: 1px;
    border-radius: 50%;
}
